<template>
  <div class="container">
    <div class="left">
      <div class="live-info">
        <div style="margin-right:20px;">当前直播间：<span style="color:red;">{{ roomTitle }}</span></div>
        <div>直播房间号：<span style="color:red;">{{ roomNo }}</span></div>
      </div>
      <useSteps @copy="copy"/>
    </div>
    <div class="right">
      <div class="message-content" ref="chat">
        <div class="message-list" v-for="(item,index) in messageList" :key="index">
          <div>
            <span class="username">{{ item.userNmae }}</span>
            <el-tag :type="item.type == 0 ? 'info' : item.type == 1 ? '' : 'success'" size="mini">
              {{ item.type == 0 ? '用户' : item.type == 1 ? '主播' : '系统' }}
            </el-tag>
          </div>
          <span class="message-text">{{ item.msg }}</span>
        </div>
      </div>
      <div class="btn">
        <el-input type="textarea" placeholder="请输入内容" v-model="msgPayload" show-word-limit></el-input>
        <el-button type="primary" size="mini" style="margin-left:10px;" @click="handlerSend">发送</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import {pushLvie} from '@/apis/live'
import {getAccessToken} from '@/apis/webscoket'
import axios from 'axios'
import useSteps from '@/components/live/useSteps'

let wsBaseUrl = 'https://wx.qzgjwh.com' // websocket 域名
export default {
  components: {useSteps},
  data() {
    return {
      accessToken: '',
      liveId: '', // 直播id
      roomNo: '', // 房间号
      roomTitle: '', // 直播间标题
      msgPayload: '', // 发送消息内容
      WebSocket: '',
      messageList: [],
      userNmae: '',
    };
  },
  activated() {
    this.liveId = this.$route.query.id
    this.roomNo = this.$route.query.roomNo
    this.roomTitle = this.$route.query.roomTitle
  },
  mounted() {
    this.createWebSocket()
    this.getUserInfo()
  },
  methods: {
    // 发送消息
    async handlerSend() {
      if (!this.msgPayload) return this.$message.error('请输入内容')

      const message = {
        msg: this.msgPayload,
        type: 1,
        userNmae: this.userNmae || '主播'
      }
      const data = {
        channel: this.roomNo,
        msgPayload: JSON.stringify(message)
      }
      const res = await axios.post(`${wsBaseUrl}/live/msg/send?accessToken=${this.accessToken}`, data)

      if (res.data.code == 200) {
        this.msgPayload = ''
        this.$message.success('发送成功')
      } else {
        this.$message.error('发送失败')
      }
    },
    // 创建webscoket
    async createWebSocket() {
      const res = await getAccessToken()
      const accessToken = res.data.accessToken
      this.accessToken = accessToken
      const wsToken = await axios.get(`${wsBaseUrl}/live/ws/token`, {params: {accessToken, channel: this.roomNo}})
      const ws = new WebSocket(`wss://wx.qzgjwh.com/live/msg?wsToken=${wsToken.data.data.wsToken}`)
      this.WebSocket = ws
      // 连接成功
      ws.onopen = () => {
        console.log('连接成功')
      }

      // 接收消息
      ws.onmessage = (e) => {
        if (e.data) {
          const data = JSON.parse(e.data)
          this.messageList.push(data)
          this.$nextTick(() => {
            let chat = this.$refs.chat
            chat.scrollTop = chat.scrollHeight
          })
        }
      }

      // 关闭连接
      ws.onclose = () => {
        console.log('关闭连接')
      }

      // 发生错误
      ws.onerror = (e) => {
        console.log('发生错误', e)
      }
    },

    // 复制推流地址 
    async copy(value) {
      const res = await pushLvie({liveRoomId: this.liveId})
      var input = document.createElement("input");   // 直接构建input
      input.value = res.data[value];  // 设置内容
      document.body.appendChild(input);    // 添加临时实例
      input.select();   // 选择实例内容
      document.execCommand("Copy");   // 执行复制
      document.body.removeChild(input); // 删除临时实例
      this.$message.success('复制成功')
    },
    getUserInfo() {
      const info = window.sessionStorage.getItem('userinfo')
      if (info) {
        this.userNmae = JSON.parse(info).userName
      }
    }
  },
};
</script>

<style lang="less" scoped>
div {
  box-sizing: border-box;
}

.container {
  display: flex;
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  height: calc(100vh - 86px);
  overflow: auto;

  .el-select {
    margin-right: 20px;
  }

  .live-info {
    display: flex;
    align-items: center;
    height: 60px;
    border-bottom: 1px #eee dashed;
    margin-bottom: 10px;
  }

  .left {
    display: flex;
    flex-direction: column;
    width: 76%;
    height: 100%;
  }

  .video-content {
    flex: 1;

    video {
      width: 100%;
      height: 100%;
    }
  }

  .right {
    position: fixed;
    right: 20px;
    top: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 2px solid #999;
    margin-left: 20px;
    height: calc(100% - 120px);
    flex: 1;

    .message-content {
      padding: 20px;
      overflow-y: scroll;

      .message-list {
        margin-bottom: 10px;

        .username {
          margin-right: 10px;
          color: #666;
        }

        .message-text {
          font-size: 14px;
          color: orange;
          white-space: pre-line;
          word-break: break-all;
          word-wrap: break-word;
        }
      }
    }

    .btn {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 80px;

      /deep/ .el-textarea__inner,
      /deep/ .el-textarea {
        height: 80px !important;
      }
    }
  }
}
</style>