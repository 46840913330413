// 主播管理
import axios from 'axios'
import QS from 'qs'
// 主播分页
export const liveUserPage = params => {
    return axios.post(`/api/liveUser/page`, QS.stringify(params)).then(res => res.data)
}
// 主播 添加 / 编辑
export const liveUserAddOnSet = params => {
    return axios.post(`/api/liveUser/addOnSet`, QS.stringify(params)).then(res => res.data)
}
// 主播删除
export const liveUserDel = params => {
    return axios.post(`/api/liveUser/del`, QS.stringify(params)).then(res => res.data)
}

// 直播列表
export const liveRoomPage = params => {
    return axios.post(`/api/liveRoom/page`, QS.stringify(params)).then(res => res.data)
}

// 直播添加
export const liveRoomAdd = params => {
    return axios.post(`/api/liveRoom/add`, QS.stringify(params)).then(res => res.data)
}
// 直播编辑
export const liveRoomSet = params => {
    return axios.post(`/api/liveRoom/set`, QS.stringify(params)).then(res => res.data)
}
// 直播删除
export const liveRoomDel = params => {
    return axios.post(`/api/liveRoom/del`, QS.stringify(params)).then(res => res.data)
}

// 直播推流
export const pushLvie = params => {
    return axios.post(`/api/live/pushLvie`, QS.stringify(params)).then(res => res.data)
}

// 获取直播商品列表
export const liveRoomGoodsList = params => {
    return axios.post(`/api/liveRoomGoods/list`, QS.stringify(params)).then(res => res.data)
}

// 添加直播商品
export const liveRoomGoodsAdd = params => {
    return axios.post(`/api/liveRoomGoods/add`, QS.stringify(params)).then(res => res.data)
}

// 删除直播商品
export const liveRoomGoodsDel = params => {
    return axios.post(`/api/liveRoomGoods/del`, QS.stringify(params)).then(res => res.data)
}

// 打赏列表
export const liveRewardList = params => {
    return axios.get(`/api/liveReward/list`, {params}).then(res => res.data)
}

// 礼物列表
export const liveGiftList = params => {
    return axios.get(`/api/liveGift/list`, {params}).then(res => res.data)
}

// 删除礼物
export const liveGiftDel = params => {
    return axios.post(`/api/liveGift/del`, QS.stringify(params)).then(res => res.data)
}

// 添加礼物
export const liveGiftAdd = params => {
    return axios.post(`/api/liveGift/add`, QS.stringify(params)).then(res => res.data)
}

// 修改礼物
export const liveGiftSet = params => {
    return axios.post(`/api/liveGift/set`, QS.stringify(params)).then(res => res.data)
}

// 回放列表
export const liveBackList = params => {
    return axios.get(`/api/liveBack/list`, {params}).then(res => res.data)
}

// 添加回放
export const liveBackAdd = params => {
    return axios.post(`/api/liveBack/add`, QS.stringify(params)).then(res => res.data)
}

// 修改回放
export const liveBackSet = params => {
    return axios.post(`/api/liveBack/set`, QS.stringify(params)).then(res => res.data)
}

// 删除回放
export const liveBackDel = params => {
    return axios.post(`/api/liveBack/del`, QS.stringify(params)).then(res => res.data)
}