<template>
  <div class="conteainer">
    <div class="header">
      <h3>操作步骤</h3>
      <el-button size="small " type="primary" @click="copy('pushArtcUrl')">复制直播地址1</el-button>
      <el-button size="small " type="primary" @click="copy('pushRtmpUrl')">复制直播地址2</el-button>
    </div>
    <ul>
      <li>没有 OBS工具 请 <a href="https://file.qzgjwh.com/OBS-Studio-27.0.1180-Full-Installer-x86.exe">下载 OBS</a></li>
      <li>
        <div>打开OBS推流工具</div>
        <div class="content">
          <div>1 右侧点击设置</div>
          <el-image class="img" :src="require('@/assets/1.png')" :previewSrcList="[require('@/assets/1.png')]"
                    fit="fill"></el-image>
          <div>2 点击推流 => 选择自定义 => 复制直播地址放到服务器 => 确定</div>
          <el-image class="img" :src="require('@/assets/2.png')" :previewSrcList="[require('@/assets/2.png')]"
                    fit="fill"></el-image>
          <div>3 点击输出 => 输出模式选择高级 => 关键帧间隔设置不超过3秒 => 配置设置为baseline => 确定</div>
          <el-image class="img" :src="require('@/assets/3.jpg')" :previewSrcList="[require('@/assets/3.jpg')]"
                    fit="fill"></el-image>
          <div>4 点击 + 号 => 选择显示器采集(即可分享屏幕) => 确定</div>
          <el-image class="img" :src="require('@/assets/4.jpg')" :previewSrcList="[require('@/assets/4.jpg')]"
                    fit="fill"></el-image>
          <div>5 点击 - 号 => 确定 => 可退出分享屏幕</div>
          <el-image class="img" :src="require('@/assets/5.png')" :previewSrcList="[require('@/assets/5.png')]"
                    fit="fill"></el-image>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  emits: ['copy'],
  methods: {
    copy(val) {
      this.$emit('copy', val)
    }
  }
}
</script>
<style lang="less" scoped>
.conteainer {
  padding: 20px;

  .header {
    display: flex;
    align-items: center;
    height: 50px;
    margin-bottom: 20px;

    h3 {
      margin-right: 30px;
    }
  }

  li {
    line-height: 30px;

    .img {
      width: 50%;
    }

    .content {
      padding-left: 20px;
      color: #f00;
      font-size: 14px;
    }
  }
}
</style>